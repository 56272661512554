<!-- System: STA
    Purpose: Assign project display modal in
            team member page to assign projects to
            team members etc         
-->
<template>
  <div>
    <!-- Assign Project Modal -->
    <v-dialog v-model="AssignProjects" width="800" @click:outside="onHidden()">
      <v-card
        body-class="p-0"
        class="p-0"
        id="assign_projects"
        ref="assign_projects"
      >
        <v-card-title class="headline white lighten-2 justify-center">
          Assign Projects
          <v-spacer></v-spacer>
          <v-btn icon @click="(AssignProjects = false), onHidden()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- Search Bar For Projects -->
        <v-row>
          <v-col cols="12" md="6" offset-md="6" class="mt-3">
            <v-text-field label="Search Project" v-model="search">
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Labels -->
        <v-row class="mt-4 m-2">
          <v-col cols="12" md="3" class="mb-3 statusIndicators">
            <div
              @click="changeProjects('assigned')"
              class="assignedProjects mb-3"
              title="Show All Members"
            >
              <div></div>
              <span>&nbsp;Assigned</span>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="mb-3 statusIndicators">
            <div
              @click="changeProjects('invited')"
              class="invitedProjects mb-3"
              title="Members are working on project"
            >
              <div></div>
              <span>&nbsp;Invited</span>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="mb-3 statusIndicators">
            <div
              @click="changeProjects('unassigned')"
              class="unAssignedProjects mb-3"
              title="Members are inactive and not working on project"
            >
              <div></div>
              <span>&nbsp;Unassigned</span>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="mb-3 statusIndicators">
            <div
              @click="changeProjects('all')"
              class="allProjects mb-3"
              title="Show All Members"
            >
              <div></div>
              <span>&nbsp;All</span>
            </div>
          </v-col>
        </v-row>
        <!-- Project Components  -->
        <v-row class="m-0 ml-4">
          <div
            :key="index"
            class="m-0 mb-3 pl-0 pr-2"
            v-for="(project, index) in unassignedProject"
            v-if="showUnassignedProject"
          >
            <Project
              :project="project"
              @click.native="assignProject(project)"
              class="hand"
            />
          </div>
          <div
            :key="index"
            class="m-0 mb-3 pl-0 pr-2"
            v-for="(project, index) in assignedProject"
            v-if="showAssignedProject"
          >
            <Project
              :key="index"
              :project="project"
              @click.native="assignProject(project)"
              class="hand"
            />
          </div>
          <div
            :key="index"
            class="m-0 mb-3 pl-0 pr-2"
            v-for="(project, index) in invitedProject"
            v-if="showInvitedProject"
          >
            <Project
              :key="index"
              :project="project"
              @click.native="assignProject(project)"
              class="hand"
            />
          </div>
          <div
            :key="index"
            class="m-0 mb-3 pl-0 pr-2"
            v-for="(project, index) in sorted_projects"
            v-if="showAll"
          >
            <Project
              :key="index"
              :project="project"
              @click.native="assignProject(project)"
              class="hand"
            />
          </div>
          <!-- Render If No Project Found -->
          <v-row v-if="noProjectFound" class="d-flex justify-center">
            <h6 class="text-center mt-5 mb-10">No Projects Found.</h6>
          </v-row>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Dialog/Modal For Assigned/Archived/Delete Project -->
    <v-dialog
      v-model="assignedProjectDialog"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="headline red lighten-2">
          Are you sure?
        </v-card-title>
        <v-card-text class="mt-4">
          <h4>
            {{ title }}
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="assignedProjectConditions == 'is_assigned'">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="assignedProjectDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(assignedProjectDialog = false), deleteOrArchive(0)"
          >
            Delete
          </v-btn>
          <v-btn
            color="warning"
            outlined
            text
            @click="(assignedProjectDialog = false), deleteOrArchive(1)"
          >
            Archive
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="assignedProjectConditions == 'is_invited'">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="assignedProjectDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(assignedProjectDialog = false), withdraw()"
          >
            Withdraw
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="assignedProjectConditions == 'new-invite'">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="assignedProjectDialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(assignedProjectDialog = false), invite()"
          >
            Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MemberTeam",
  components: { Project: () => import("@/components/TeamMembers/Projects") },
  data() {
    return {
      AssignProjects: false,
      assignedProjectDialog: false,
      assignedProjectConditions: "",
      projectAssigned: "",
      title: "",
      member: null,
      projects: [],
      search: "",
      team_search: "",
      showUnassignedProject: false,
      showAssignedProject: false,
      showInvitedProject: false,
      showAll: true,
    };
  },
  filters: {
    // Filter definitions
    Upper(value) {
      return value.isAssigned();
    },
  },
  mounted() {
    this.$eventBus.$on("assignProjects", (member) => {
      this.member = member;
      this.AssignProjects = true;
    });
  },
  watch: {
    member: function (member) {
      if (member) this.getProjects(member);
    },
  },
  computed: {
    sorted_projects() {
      this.search = this.search.trimStart();
      let searchedProjects = [];
      this.projects.forEach((project) => {
        if (
          project.project.project_name
            .toLowerCase()
            .includes(this.search.toLowerCase())
        ) {
          searchedProjects.push(project);
        }
      });
      return searchedProjects;
    },
    unassignedProject() {
      return this.sorted_projects.filter(
        (project) => !project.is_assigned && !project.is_invited
      );
    },
    assignedProject() {
      return this.sorted_projects.filter((project) => project.is_assigned);
    },
    invitedProject() {
      return this.sorted_projects.filter((project) => project.is_invited);
    },
    noProjectFound() {
      return (
        !this.projects.length ||
        (this.showUnassignedProject && !this.unassignedProject.length) ||
        (this.showAssignedProject && !this.assignedProject.length) ||
        (this.showInvitedProject && !this.invitedProject.length)
      );
    },
  },
  methods: {
    /**
     * This function is resposible for filter
     * the list of projects
     */
    changeProjects(filter) {
      this.showUnassignedProject = false;
      this.showAssignedProject = false;
      this.showInvitedProject = false;
      this.showAll = false;
      if (filter === "assigned") {
        this.showAssignedProject = true;
      } else if (filter === "invited") {
        this.showInvitedProject = true;
      } else if (filter === "unassigned") {
        this.showUnassignedProject = true;
      } else {
        this.showAll = true;
      }
    },
    /**
     * This function is resposible for clear
     * data when modal close
     */
    onHidden() {
      this.member = null;
    },
    /**
     * This function is resposible for getting
     * projects of that user
     */
    getProjects(member) {
      let company_id = this.$route.params.id;
      let member_id = member.id;
      this.$store
        .dispatch("custom/member_assign_projects", { company_id, member_id })
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          let data = response.data.data;
          let temp_projects = [];
          data.assignedProjects.forEach((project) => {
            temp_projects.push({ project: project, is_assigned: true });
          });
          data.invitedProjects.forEach((project) => {
            temp_projects.push({ project: project, is_invited: true });
          });
          data.not_my_projects.forEach((project) => {
            temp_projects.push({ project: project, is_not_my_project: true });
          });
          this.$store.commit(
            "custom/set_team_members_project_list",
            data.company_projects
          );
          this.projects = temp_projects;
          this.sortProjects();
        });
    },
    /**
     * This function is resposible for adding
     * project to that user
     */
    assignProject(project) {
      this.projectAssigned = project;
      if (this.member.member_status === "archive") return;
      if (project.is_assigned) {
        this.assignedProjectDialog = true;
        this.assignedProjectConditions = "is_assigned";
        this.title =
          "You are going to remove " +
          this.member.name +
          " from this project. Removing the member will delete all the member data against this project. Archiving will only deactivate the member. Following action will also be performed on the member if he is not associated with any other projects.";
      } else if (project.is_invited) {
        this.assignedProjectDialog = true;
        this.assignedProjectConditions = "is_invited";
        this.title =
          "You want to withdraw the invitation from " +
          this.member.name +
          ". Please keep in mind that the member will also archived from company if he is not associated with any other project.";
      } else {
        this.assignedProjectDialog = true;
        this.assignedProjectConditions = "new-invite";
        this.title =
          "You want to send the project invitation email to " +
          this.member.name;
      }
    },
    /**
     * This function is resposible for deleting/
     * archive project from user
     */
    deleteOrArchive(status) {
      let project = this.projectAssigned;
      let req_data = {
        member_id: this.member.id,
        project_id: this.projectAssigned.project.id,
        company_id: this.$route.params.id,
        keep_data: status,
      };
      this.$store
        .dispatch("custom/remove_from_project", req_data)
        .then((response) => {
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            project.is_assigned = false;
            project.is_invited = false;
            project.is_not_my_project = true;
          } else {
            this.$store.commit("custom/toggle_progress_loader", false);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
    /**
     * This function is resposible for withdraw
     * project invitation from user
     */
    withdraw() {
      let status = 1; // To keep the user data safe
      let project = this.projectAssigned;
      let req_data = {
        member_id: this.member.id,
        project_id: this.projectAssigned.project.id,
        company_id: this.$route.params.id,
        keep_data: status,
      };
      this.$store
        .dispatch("custom/remove_from_project", req_data)
        .then((response) => {
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Invitation has been withdrawn from user successfully!.",
              snackbarColor: "green",
            });
            project.is_assigned = false;
            project.is_invited = false;
            project.is_not_my_project = true;
          } else {
            this.$store.commit("custom/toggle_progress_loader", false);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
    /**
     * This function is resposible for sending
     * invitation of project
     */
    invite() {
      let project = this.projectAssigned;
      let data = {
        member_id: this.member.id,
        project_id: this.projectAssigned.project.id,
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/invite_existing_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_progress_loader", false);
          if (response.data.error === false) {
            project.is_assigned = false;
            project.is_not_my_project = false;
            project.is_invited = true;
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            let company_id = this.$route.params.id;
            this.$store.dispatch("custom/get_team_members", {
              company_id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_progress_loader", false);
        });
    },
    /**
     * This function is resposible for sorting
     * of project
     */
    sortProjects() {
      this.projects.sort((a, b) => {
        let x = a.project.project_name.toLowerCase();
        let y = b.project.project_name.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.statusIndicators {
  cursor: pointer;
}
.assignedProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: palegreen;
}

.unAssignedProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: #36454f;
}

.invitedProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: orange;
}

.allProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: #2758f6;
}
</style>
